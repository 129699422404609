<template>
  <div class="about" > 
    <a-spin :spinning="spinnings" tip="資料載中..."> 
    <div class="d-flex flex-column min-vh-100">
    <Menubar msg="test" @query="query" @getwinsize="getwinsize" ref="MyBar" />
        <b-alert
          v-model="showTop"
          class="position-fixed fixed-top m-0 rounded-0"
          style="z-index: 2000;"
          variant="danger"
          dismissible
        >          
          <span v-text="message" />

        </b-alert>   
          <div :style="{ 'max-width': '980px',  'margin':  windowsize.width>=991?'0px auto':'' }" >
           <Inforact v-if="objs!==null" :obj="objs"  @loadings="loadings"  ref="con1" />
          </div>     
      <div class="sidenav" v-if="windowsize.width>=991"> 
          <Actapply :obj="objs" @showmessage="showmessage" @openshare="openshare"  /> 
      </div>        
    <div class="wrapper flex-grow-1"></div>      
    <Footbar msg="test" />
    <div class="applyfooter" v-if="windowsize.width<=991">
      <Actapplybottom :obj="objs" @showmessage="showmessage" @openshare="openshare" />
    </div>
    </div>
    <b-modal ref="my-loading" id="modal-1" title="分享資訊" @hide="onHide" centered hide-footer>
      <Shareurl :obj="objs"  />
    </b-modal>
  </a-spin>
  </div>
</template>
<script>
import { macService } from '../_services'
import * as moment from "moment/moment";
import Inforact from './panel/Inforact'
import Actapply from './panel/Actapply' 
import Actapplybottom from './panel/Actapplybottom.vue'
import Shareurl from '../shareurl/Shareurl.vue'
import Menubar from '@/components/Menubar.vue'
import Footbar from '@/components/Footbar.vue'

const queryData = params => {
  return macService.macfind(params.id) 
};


export default {
  name: 'Actinfo',
  components: {
    Menubar,
    Footbar,
    Inforact,
    Shareurl,
    Actapply,
    Actapplybottom,
  }, 
  props: {
    msg: String
  },
  metaInfo: {
      title: '活動清單',  
      titleTemplate: null,
      meta: [
            {
              property: 'og:title',
              content: '活動清單',
              vmid: 'og:title'
            },
            {
              property: 'og:description',
              content: '這是活動清單',
              vmid: 'og:description'
            },
            {
              property: 'og:image',
              content: 'https://s3.ap-northeast-1.amazonaws.com/my-hosted-apiuplod/upload/0fbc01f0-7b9d-49e2-8be8-e1aa1b81b6bcBBVxVee123.jpg',
              vmid: 'og:image'
            }                            
          ],             
  }, 
  data() {
    return {
      showTop: false,  
      spinnings: true,         
      windowsize: {
            width: 0,
            height: 0
        },        
      classtheme:null,
      objs: {},      
      uId:'',
      message:'',
    };
  },  
  mounted(){
        //console.log(this.$route.query.userId);
    this.uId = this.$route.params.id
    this.fetch({id:this.uId});
    this.$refs["MyBar"].closebar(false);
  },
  methods: {
    query(rs){
      this.classtheme = rs
    },
    getwinsize(val){
      this.windowsize.width = val.width
      this.windowsize.height = val.height
    },         
    fetch(params = {}) {
      queryData({
        ...params,
      }).then(({ data }) => {
          this.objs = data;
          this.$refs['con1'].getOrg(this.objs.orgid);
      });
    },
    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm");
    },
    showmessage(val){
          this.message = val
          this.showTop = true;          
          setTimeout(() => {   
              this.showTop = false;
              this.message =''
              clearInterval();
          }, 4000);       
    },
    onHide(evt) {
          if(evt.trigger === "backdrop"){
              evt.preventDefault();
          }
      }, 
    openshare(){
      this.$refs['my-loading'].show()
    },
    loadings(val){
      this.spinnings =val
    },       
  },     
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.sidenav {
  width: 230px;
  position: fixed;
  z-index: 1;
  top: 130px;
  right: 0;
  overflow-x: hidden;
  padding: 0;
}
.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 25px;
  color: #2196F3;
  display: block;
}

.sidenav a:hover {
  color: #064579;
}
.applyfooter {
   position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   height: 60px;
   background-color: #f0f8f8;
   color: #f0f8f8;
   text-align: center;
   
}


</style>

