<template>
  <div>
    <div style="width:100%;max-height:60px;" >  
      <div class="leftbtn" @click="share()"><b-icon icon="chat-quote-fill"  aria-hidden="true"></b-icon> Line</div>   
      <div class="rightbtn" @click="sharefb()" ><b-icon icon="facebook"   aria-hidden="true"></b-icon>
        FB
      </div>   
    </div>  
  </div>
</template>

<script>

export default {
  name: "Shareurl",
  props: {
    obj: Object, 
  },  
  data() {
    return {  
      sharingurl: 'https://yunlingo.healthygo.tw'+this.$route.path.replace("line_apply", "act_info") ,      
      networks: [
              { network: 'line', name: 'Line', icon: 'fab fah fa-lg fa-line', color: '#00c300' },
              { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },              
            ]
    };
  },
  computed: {
  },
  methods: {
    share(){
      var link = 'https://social-plugins.line.me/lineit/share?url='+this.sharingurl;
      link += '&text=雲林縣健康活動參與平台 %0a 活動分享  %0a 活動名稱 : '+this.obj.actitle;
      link += '%0a 活動時間(起訖) : %0a' + this.obj.sdate + '~ ' + this.obj.edate;
      link += '%0a 活動連結如下 : ';
      //var link = 'https://social-plugins.line.me/lineit/share?url=http%3A%2F%2Fyahoo.com.tw&text=testtestest '
      var iWidth=500; //彈出視窗的寬度;
      var iHeight=500; //彈出視窗的高度;
      var iTop = (window.screen.availHeight-30-iHeight)/2; //獲得視窗的垂直位置;
      var iLeft = (window.screen.availWidth-10-iWidth)/2; //獲得視窗的水平位置;
      window.open(link, '_blank','height='+iHeight+',,innerHeight='+iHeight+',width='+iWidth+',innerWidth='+iWidth+',top='+iTop+',left='+iLeft+',toolbar=no,menubar=no,scrollbars=auto,resizeable=no,location=no,status=no');
    },
    sharefb(){
      var link = 'https://www.facebook.com/sharer/sharer.php?u='+this.sharingurl;
      link += '&text=雲林縣健康活動參與平台 %0a 活動分享  %0a 活動名稱 : '+this.obj.actitle;
      link += '%0a 活動時間(起訖) : %0a' + this.obj.sdate + '~ ' + this.obj.edate;
      link += '%0a 活動連結如下 : ';
      link += ';src=sdkpreparse'
      //var link = 'https://social-plugins.line.me/lineit/share?url=http%3A%2F%2Fyahoo.com.tw&text=testtestest '
      var iWidth=500; //彈出視窗的寬度;
      var iHeight=500; //彈出視窗的高度;
      var iTop = (window.screen.availHeight-30-iHeight)/2; //獲得視窗的垂直位置;
      var iLeft = (window.screen.availWidth-10-iWidth)/2; //獲得視窗的水平位置;
      window.open(link, '_blank','height='+iHeight+',,innerHeight='+iHeight+',width='+iWidth+',innerWidth='+iWidth+',top='+iTop+',left='+iLeft+',toolbar=no,menubar=no,scrollbars=auto,resizeable=no,location=no,status=no');
    }    
  },
  mounted () {

  },
  beforeDestroy() {}
};
</script>
<style scoped>
.leftbtn {
    background-color: #00bd00;
    color:#f9fbfc;
    width:50%; 
    height:50px; 
    float:left; 
    display:inline;
    text-align:center;
    line-height:50px;
}
.leftbtn:hover {
    background-color: #b6c8cc;
    color:#ffffff;
    cursor: pointer;
}

.rightbtn {
    background-color: #0d8af0;
    color:#f9fbfc;
    width:50%; 
    height:50px; 
    float:left; 
    display:inline;
    border-width:1px;
    border-color: #65b5f7;
    border-left-style:dotted;
    text-align:center; 
    line-height:50px;
}

.rightbtn:hover {
    background-color: #316997;
    cursor: pointer;
}
 
</style>