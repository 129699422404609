<template>
  <div>
    <div v-if="JSON.stringify(obj)!=='{}'" style="width:100%;max-height:60px;" >       
        <div class="modbtn" @click="opens"> <b-icon icon="share" aria-hidden="true"></b-icon> 分享</div>           
        <div class="leftbtn" @click="viewour"><b-icon icon="person-circle" aria-hidden="true"></b-icon> 主辦單位</div> 
        <div v-if="obj.states=='Y'" class="rightbtn" @click="joinact"><b-icon icon="bar-chart-fill" aria-hidden="true"></b-icon> 立即報名</div> 
        <div v-else style="background-color: #65b5f7;width:55%; height:60px; float:left; "  />             
    </div>  
  </div>
</template>

<script>
import {mapState} from 'vuex';
import { checktokens } from "../../api/utils";
import { joinService } from '../../_services'
import router from '../../router'

const queryData = params => {
  return joinService.check(params) 
};

export default {
  name: "Actapplybottom",
  props: {
    obj: Object, 
  },
  data() {
    return {     
      xurl: "",
      imgs: {
        width: 0,
        height: 0
      },

    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
  },
  methods: {

    catImg(imgs) {
      return require(`../../assets/${imgs}`);
    },

    covert(type,val){
      if(type=='theme'){        
        let rs= this.mytheme.filter((el)=> { return el.value === val});
        return rs[0].title;
      }
    },

    joinact(){
        if(this.user!==null){
            let verify= checktokens(String(this.status.token))                    
            if(verify===null){
                this.$emit("showmessage",'請先登入, 謝謝!')    
            }else{
              let rs = this.fetch({uid:this.user.data.user.id,fid:this.obj.id})                  
                  rs.then((value)=> {
                    if(value){
                        this.$emit("showmessage","您已經報名了!謝謝")  
                    }else{
                        router.push({ name: 'Regjoin', params: { id: this.obj.id, type:'N' }}) 
                    }
                  }); 
            }
        }else{
          this.$emit("showmessage",'請先登入, 謝謝!')    
        }
    },

    viewour(){   
        router.push({ name: 'Outorg', params: { id: this.obj.orgid,type:'N' }})   
    },

    fetch(params = {}) {
      return new Promise((resolve)=> {
        queryData({
          ...params,
        }).then(({ data }) => { 
            resolve(data.data)      
        });
      })
    },     
    opens(){
      this.$emit("openshare")  
    }

  },
  mounted () {
        /*this.themedata = this.mytheme 
        this.category=this.mycategory */
  },
  beforeDestroy() {}
};
</script>
<style scoped>
.leftbtn {
    background-color: #aedbe3;
    color:#4b5152;
    width:30%; 
    height:60px; 
    float:left; 
    display:inline;
    text-align:center;
    line-height:60px;
}
.leftbtn:hover {
    background-color: #b6c8cc;
    color:#ffffff;
    cursor: pointer;
}
.modbtn{
    background-color: #94c2ca;
    color:#4b5152;
    width:15%; 
    height:60px; 
    float:left; 
    display:inline;
    text-align:center;
    line-height:60px;
}
.modbtn:hover {
    background-color: #abb9bd;
    color:#ffffff;
    cursor: pointer;
}

.rightbtn {
    background: linear-gradient(90deg, #65b5f7 0%, #4ea0e4 35%, #418fd0 100%);
    width:55%; 
    height:60px; 
    float:left; 
    font-weight:bold;
    display:inline;
    border-width:1px;
    border-color: #65b5f7;
    border-left-style:dotted;
    text-align:center; 
    line-height:60px;
}

.rightbtn:hover {
    background: linear-gradient(90deg, #7dc3fd 0%, #62b5f8 35%, #418fd0 100%);
    cursor: pointer;
}
 
</style>


