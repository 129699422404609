<template>
  <div>   
    <div v-if="JSON.stringify(obj)!=='{}'" >
      <b-card
        header=""
        header-border-variant="light"
        header-bg-variant="light"
        align="center"
        class="card-3"
      >
        <b-card-body style="max-width:230px;padding:0px 0px 5px 0px;" class="overflow-auto">
          <b-card-text>
            <img  class="bimg" :src="obj!==''? obj.url:catImg('event_1080x540.png')" />
            <div class="text-left" style="padding:5px 5px 5px 5px;">
              <strong><span style="font-size: 14px;" v-text="obj.actitle"></span></strong>
              <div class="classempy">
                <b-icon icon="clock" variant="success"></b-icon> 活動時間 : <br/>
                <span style="font-size: 10px;" v-text="obj.sdate +' '+  obj.stime.substring(0, 5) +' ~ ' + (obj.edate==obj.sdate?'':obj.edate) +' '+  obj.etime.substring(0, 5)"></span>
              </div>
              <div class="classempy">
                <b-icon icon="geo-alt" variant="success"></b-icon> 活動地點 : <br/>
                <span style="font-size: 10px;" v-text="obj.address"></span>
              </div>   
              
            </div>
          </b-card-text>
        </b-card-body>
           <template #footer>
                <div class="leftbtn" @click="opens" >分享
                  <b-icon icon="share"  aria-hidden="true"></b-icon>
                </div>             
                <div v-if="obj.states=='Y'" class="rightbtn" @click="joinact">立即報名
                  <b-icon icon="chevron-double-right" aria-hidden="true"></b-icon>
                </div>
                <div v-else style="background-color: #3887c7;width:70%; height:50px; float:left; "  />               
           </template>
      </b-card>
    </div>
  </div>
</template>

<script>
import {mapState,mapActions} from 'vuex';
import { checktokens } from "../../api/utils";
import router from '../../router'


export default {
  name: "Actapply",
  props: {
    obj: Object, 
  }, 
  data() {
    return {            
      xurl: "",
      objs:[],
      imgs: {
        width: 0,
        height: 0
      },

    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
  },
  methods: {
    ...mapActions("account", ["checktoken", "logout"]),
    catImg(imgs) {
      return require(`../../assets/${imgs}`);
    },

    joinact(){
        if(this.user!==null){
            let verify= checktokens(String(this.status.token))                  
            if(verify===null){
               //this.$message.error('請重新登入, 謝謝',2); 
               this.$emit("showmessage",'請先登入, 謝謝!')                   
            }else{
              router.push({ name: 'Regjoin', params: { id: this.obj.id, type:'N' }})                                 
            }
        }else{
          //this.$message.error('請先登入, 謝謝',2); 
          this.$emit("showmessage",'請先登入, 謝謝!')             
        }           
    },    

    covert(type,val){
      if(type=='theme'){        
        let rs= this.mytheme.filter((el)=> { return el.value === val});
        return rs[0].title;
      }
    }, 
    
    opens(){
      this.$emit("openshare")  
    }


  },
  mounted () {
        /*this.themedata = this.mytheme 
        this.category=this.mycategory */
  },
  beforeDestroy() {}
};
</script>
<style scoped>
.card-body {
  padding: 5px 0px 5px 0px;
}
.ddiv {
  padding: 7px;
  border-radius: 17px;
  margin: 5px auto;
  box-shadow: 1px 1px 15px -5px rgb(46, 44, 44);
  transition: all 0.3s ease;
}
.card-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.bimg {
  max-height: 100%;
  max-width: 100%;
  opacity: 0.7;
  width: auto;
  height: auto;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.classempy{
  padding:0;
}

.bton {
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: 40px;
  position: absolute;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  bottom: 1750px;
  left: 26%;
  right: 0;
}

.rightbtn {
    background: linear-gradient(90deg, #65b5f7 0%, #4ea0e4 35%, #418fd0 100%);
    width:70%; 
    height:50px; 
    float:left; 
    color: #ecf2f7;
    font-weight:bold;
    display:inline;
    border-width:1px;
    border-color: #65b5f7;
    border-left-style:dotted;
    text-align:center; 
    line-height:50px;
}
.leftbtn{
    background-color: #3887c7;
    width:30%; 
    height:50px; 
    float:left; 
    color: #ffffff;
    display:inline;
    border-width:1px;
    border-color: #65b5f7;
    border-left-style:dotted;
    text-align:center; 
    line-height:50px;
}

.rightbtn:hover {
    background: linear-gradient(90deg, #7dc3fd 0%, #62b5f8 35%, #418fd0 100%);
    cursor: pointer;
}
.leftbtn:hover {
    background: #75808b;
    cursor: pointer;
}
.card {
}
.card-body{
}
.card-footer {
  padding:1px;
}
</style>


